<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-card class='box-card'>
          <div slot='header' class='clearfix'>
            <span class='title'>刊例提报信息</span>
            <el-button type='text' icon='el-icon-refresh' @click='getItemInfo' :loading='loading'
                       style='font-size: 1.2em'></el-button>
            <el-link style='float: right' class='fanhui-button'
                     type='primary'
                     icon='el-icon-d-arrow-left'
                     @click='goBack'>返回
            </el-link>
          </div>
          <div class='plc-info'>
            <el-tabs v-model='activeName'>
              <el-tab-pane label='KOL刊例' name='first'>
                <panel-view :form-data='formData' :platform-type='platformType' style='width: 100%'></panel-view>
              </el-tab-pane>
              <el-tab-pane label='Excel预览' name='second'>
                <table-row-view :data-list='[formData]' :columns='columns'></table-row-view>
              </el-tab-pane>
            </el-tabs>

          </div>
        </el-card>
        <br />
        <ApplyPanel :plc-item-id='plcItemId' v-if="userPermissions.indexOf('plc_check')>-1 && showApply&&platformType"
                    :platform-type='platformType' @change='getItemInfo' />
      </div>
    </PageHeaderLayout>

  </div>

</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import PriceGrids from '@/pages/publication/compoents/PriceGrids'
import { mapGetters } from 'vuex'
import ApplyPanel from '@/pages/publication/ApplyPanel'
import PanelView from '@/pages/publication/compoents/PanelView'
import TableRowView from '@/pages/publication/compoents/TableRowView'

export default {
  name: 'PlcItemDetail',
  components: { TableRowView, PanelView, ApplyPanel, PriceGrids, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])

  },
  data() {
    return {
      activeName: 'first',
      showApply: false,
      plcItemId: null,
      loading: false,
      formData: {},
      platformType: null,
      columns: []
    }
  },
  methods: {
    async loadSetting() {
      let { info } = await this.$api.getPlatformSettingInfoByPlatformCode(this.formData.platform_code)
      this.platformType = info.type || {}
    },
    //刷新一行数据
    async getItemInfo() {
      this.formData = {}
      let id = this.plcItemId || null
      let { info, setting } = await this.$api.getPublicationItemInfo(id)
      // await this.loadSetting()
      this.platformType = setting.type || {}
      this.columns = setting.columns || {}
      await this.$nextTick(() => {
        info.index = 1
        this.formData = info || {}
      })
      setTimeout(() => {
        this.showApply = true
      }, 1000)

    },
    openUrl(href) {
      let url = ['http://', 'https://', '//'].indexOf(href) > -1 ? href : `https://${href}`
      window.open(url, '_blank')
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.platformType = this.$route.query && this.$route.query.type
    this.plcItemId = this.$route.params && this.$route.params.id
    this.getItemInfo()

  }
}
</script>

<style scoped>

.title {
  font-size: 1.2em;
  font-weight: 800;
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
}

.plc-info {
  width: 100%;
  box-sizing: border-box;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.plc-item {
  display: flex;
  flex-direction: column;
  /*border: #00feff 1px dashed;*/
  padding: 10px;
  /*flex: 4;*/
  border-radius: 10px;
  /*background: #303133;*/
  /*color: #ffffff;*/
  margin: 5px;
  position: relative;
}

.p-service {
  margin: 5px;
}

.intro {
  /**
  首行缩进
   */
  /*text-indent: 2em;*/
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px 5px;
}

.quote {
  /*border: #00feff 1px dashed;*/
}

.nickname {
  margin-top: 10px;
  margin-bottom: 10px;
}

/**
红人标签
 */
.tag-alias {
  position: absolute;
  font-size: 10px;
  font-weight: 800;
  text-align: center;
  /*padding: 2px;*/
  right: 5%;
  top: 5%;
  height: 16px;
  width: 40px;
  /*border: #F56C6C 1px solid;*/
  color: #F56C6C;
  opacity: 0.9;
}

.padding-effect {
  padding: 20px 10px;
}


.border-effect {
  border: #e97ca1 1px dashed;
}

/**
阴影效果
 */
.shadow-effect {
  /*box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1) inset,*/
  box-shadow: 5px 5px 10px rgba(255, 49, 118, 0.1) inset,
  -5px -5px 10px white inset;
  /*box-shadow: 5px 5px 10px #000 inset; !* 水平偏移 垂直偏移 模糊半径 颜色 *!*/
}

.price-mixed {
  border: #f2f2f2 1px solid;
  padding: 10px 10px;
  border-radius: 5px;
  min-width: 220px;
  width: 280px;
}
</style>
